import { Asset } from '@graphcommerce/graphcms-ui'
import { Image } from '@graphcommerce/image'
import { IconButton, Link, Container, Grid, Typography, Box } from '@mui/material'
import { useState } from 'react'
import { NewsletterField } from '../Footer/NewsletterField/index'
import { UspsKeurmerken } from '../Usps/UspsKeurmerken'
import { FooterQueryFragment } from './FooterQueryFragment.gql'
import { MobileFooterUsps } from './MobileFooterUsps'

export type FooterProps = FooterQueryFragment

export function Footer(props: FooterProps) {
  const { footer, keurmerkUsps, usps } = props
  const [activeIndex, setActiveIndex] = useState<number | null>(null)

  return (
    <Box sx={{ bgColor: 'background.default' }}>
      {footer?.newsletterField?.text ? (
        <Box
          sx={(theme) => ({
            [theme.breakpoints.up('md')]: {
              display: 'none',
            },
          })}
        >
          <NewsletterField content={footer.newsletterField.text} />
        </Box>
      ) : (
        ''
      )}
      <Box
        sx={(theme) => ({
          py: theme.spacings.lg,
          '& .MuiGrid-container': { gap: { md: theme.spacings.xxs } },
        })}
      >
        <MobileFooterUsps usps={usps} />
        <Container maxWidth='lg'>
          <Grid
            container
            sx={{
              justifyContent: 'space-between',
            }}
          >
            {footer?.footerMenuLists.map((menu, index) => {
              if (!menu.menuListItems) return null
              const menuItems = menu.menuListItems
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  key={menu.id}
                  sx={(theme) => ({
                    lineHeight: '1.5',

                    [theme.breakpoints.between('md', 'lg')]: {
                      maxWidth: '22%',
                      flexBasis: '22%',
                    },
                    [theme.breakpoints.up('lg')]: {
                      maxWidth: '22%',
                      flexBasis: '22%',
                    },
                  })}
                >
                  {menu?.title ? (
                    <Typography
                      onClick={() => {
                        setActiveIndex(activeIndex || activeIndex === index ? null : index)
                      }}
                      variant='subtitle2'
                      component='div'
                      dangerouslySetInnerHTML={{
                        __html: menu.title.replace('[[', '<span>').replace(']]', '</span>'),
                      }}
                      sx={(theme) => ({
                        fontSize: theme.typography.h4,
                        fontWeight: theme.typography.fontWeightRegular,
                        mb: 1,
                        '& span': {
                          fontWeight: '700',
                        },
                      })}
                    />
                  ) : (
                    ''
                  )}
                  <Box
                    component='ul'
                    sx={[
                      { padding: 0, flexDirection: 'column', listStyle: 'none', marginTop: 0 },
                      activeIndex !== index &&
                        ((theme) => ({
                          [theme.breakpoints.down('sm')]: {
                            display: 'none',
                          },
                        })),
                    ]}
                  >
                    {menuItems?.map((link) => (
                      <li key={link.title}>
                        <Link
                          href={link.url}
                          color='textPrimary'
                          sx={(theme) => ({
                            fontSize: theme.typography.h5,
                            textDecoration: 'none',

                            '&:hover': {
                              color: theme.palette.primary.main,
                            },
                          })}
                        >
                          {link.title}
                        </Link>
                      </li>
                    ))}
                  </Box>
                </Grid>
              )
            })}
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              sx={(theme) => ({
                [theme.breakpoints.between('md', 'lg')]: {
                  maxWidth: '30%',
                  flexBasis: '30%',
                },

                [theme.breakpoints.up('lg')]: {
                  maxWidth: '30%',
                  flexBasis: '30%',
                },
              })}
            >
              {footer?.newsletterField?.text ? (
                <Box
                  sx={(theme) => ({
                    [theme.breakpoints.down('md')]: {
                      display: 'none',
                    },
                  })}
                >
                  <NewsletterField content={footer?.newsletterField?.text} />
                </Box>
              ) : (
                ''
              )}
              <Box
                sx={(theme) => ({
                  mt: theme.spacings.md,
                  '& > div': {
                    mb: theme.spacings.xxs,
                  },
                  '& > a': {
                    mr: theme.spacings.md,
                    ml: 0,
                    p: 0,
                  },
                  [theme.breakpoints.down('md')]: {
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    my: theme.spacings.lg,
                    mx: 0,
                    '& > div': {
                      textAlign: 'center',
                      flex: '1 0 100%',
                    },
                    '& > a': {
                      mx: theme.spacings.sm,
                    },
                  },
                  '& a:hover': {
                    backgroundColor: 'transparent',
                  },
                })}
              >
                {footer?.socialText ? (
                  <Box>
                    <Typography
                      variant='subtitle2'
                      component='div'
                      dangerouslySetInnerHTML={{
                        __html: footer?.socialText
                          ?.replace('[[', '<span>')
                          .replace(']]', '</span>'),
                      }}
                      sx={(theme) => ({
                        fontSize: theme.typography.h4,
                        fontWeight: 'normal',
                        '& span': {
                          fontWeight: 700,
                        },
                      })}
                    />
                  </Box>
                ) : (
                  ''
                )}

                {footer?.socialLinks?.map((link) => (
                  <IconButton color='inherit' edge='start' key={link.title} href={link.url}>
                    {link.asset ? (
                      <Image
                        layout='fixed'
                        src={link.asset.url}
                        width={42}
                        height={42}
                        unoptimized
                        alt={link.title}
                        sx={(theme) => ({
                          width: 'auto',
                          minHeight: '32px',
                          maxHeight: '32px',
                          aspectRatio: 'auto',

                          [theme.breakpoints.up('md')]: {
                            minHeight: '42px',
                            maxHeight: '42px',
                          },

                          '&:hover': {
                            opacity: '.7',
                          },
                        })}
                      />
                    ) : (
                      link.title
                    )}
                  </IconButton>
                ))}
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box
        sx={(theme) => ({
          py: theme.spacings.md,
          backgroundColor: theme.palette.background.paper,
        })}
      >
        <Container maxWidth='lg'>
          <UspsKeurmerken usps={keurmerkUsps} />
        </Container>
      </Box>
      <Box
        sx={(theme) => ({
          pt: theme.spacings.md,
          pb: { xs: theme.spacings.xxl, md: theme.spacings.md },
        })}
      >
        <Container>
          {footer?.footerLogo ? (
            <Box
              sx={(theme) => ({
                display: 'flex',
                alignItems: 'center',
                mb: theme.spacings.md,

                '& span': {
                  fontSize: 25,
                  fontWeight: 'medium',
                  ml: theme.spacings.xxs,
                },
                '& picture': {
                  display: 'inline-flex',
                  width: '100%',
                },

                [theme.breakpoints.up('md')]: {
                  justifyContent: 'center',
                },
              })}
            >
              <Box sx={{ display: 'flex', width: 46, height: 46 }}>
                <Asset asset={footer.footerLogo} layout='fill' quality={100} />
              </Box>

              <span>{footer.footerLogo?.alt}</span>
            </Box>
          ) : (
            ''
          )}

          <Box
            component='ul'
            sx={(theme) => ({
              p: 0,
              m: 0,
              display: 'flex',
              flexDirection: 'column',
              listStyle: 'none',

              [theme.breakpoints.up('md')]: {
                flexDirection: 'row',
                justifyContent: 'center',
              },
            })}
          >
            {footer?.legalLinks?.map((link) => (
              <li key={link.url}>
                <Link
                  href={link.url}
                  color='textPrimary'
                  sx={(theme) => ({
                    fontSize: theme.typography.h5,
                    fontWeight: 600,
                    px: { xs: 0, md: theme.spacings.sm },
                    py: { xs: theme.spacings.xxs, md: 0 },
                    display: 'inline-block',
                    lineHeight: 'normal',
                    textDecoration: 'none',

                    '&:hover': {
                      color: theme.palette.primary.main,
                    },
                  })}
                >
                  {link.title}
                </Link>
              </li>
            ))}
          </Box>
        </Container>
      </Box>
    </Box>
  )
}
