import { Box, Container } from '@mui/material'
import { UspsFooter } from '../Usps/UspsFooter'
import { UspsQueryFragment } from '../Usps/UspsQueryFragment.gql'

export type MobileFooterUspsProps = UspsQueryFragment

export function MobileFooterUsps(props: MobileFooterUspsProps) {
  const { usps } = props

  return (
    <Box
      sx={{
        display: { xs: 'flex', sm: 'none' },
      }}
    >
      <Container>
        <UspsFooter usps={usps} />
      </Container>
    </Box>
  )
}
