import { RichText, Asset } from '@graphcommerce/graphcms-ui'
import { UspList } from '@graphcommerce/next-ui'
import { Box } from '@mui/material'
import { UspsQueryFragment } from './UspsQueryFragment.gql'

export type ProductUspsProps = UspsQueryFragment

export function UspsFooter(props: ProductUspsProps) {
  const { usps } = props

  if (!usps?.uspsMultiple) return null

  return (
    <UspList
      sx={(theme) => ({
        gap: 1,
        '& img': { width: 'auto !important', minHeight: '10px', mr: 1 },
        mb: theme.spacings.md,
      })}
    >
      {usps?.uspsMultiple.map((usp) => {
        if (!usp.description && !usp.asset) return null
        return (
          <Box
            key={usp.title}
            sx={{
              display: 'flex',
              alignItems: 'center',
              color: 'text.primary',
            }}
          >
            {usp.asset ? <Asset asset={usp.asset} /> : ''}
            {usp.description ? (
              <RichText
                raw={usp.description?.raw}
                sxRenderer={{
                  paragraph: {
                    flex: '0 0 auto',
                    typography: 'subtitle1',
                    color: 'text.secondary',
                    fontWeight: 500,
                  },
                  link: {
                    typography: 'subtitle1',
                    color: 'text.secondary',
                    textDecoration: 'underline',
                  },
                }}
              />
            ) : (
              ''
            )}
          </Box>
        )
      })}
    </UspList>
  )
}
