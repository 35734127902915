import { RichText, Asset } from '@graphcommerce/graphcms-ui'
import { Grid, Link, Box, useTheme } from '@mui/material'
import { UspsQueryFragment } from './UspsQueryFragment.gql'

export type ProductUspsProps = UspsQueryFragment

export function UspsKeurmerken(props: ProductUspsProps) {
  const { usps } = props
  const theme2 = useTheme()

  if (!usps?.uspsMultiple) return null

  return (
    <Grid container>
      {usps?.uspsMultiple.map((usp) => {
        if (!usp.description || !usp.asset) return null
        return (
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            key={usp.id}
            sx={(theme) => ({
              [theme.breakpoints.down('md')]: {
                mb: theme.spacings.sm,
              },
              [theme.breakpoints.between('sm', 'md')]: {
                '&:nth-of-type(3)': {
                  mb: 0,
                },
                '&:nth-of-type(4)': {
                  mb: 0,
                },
              },
            })}
          >
            <Box
              key={usp.title}
              sx={{
                display: 'flex',
              }}
            >
              <Box
                sx={(theme) => ({
                  mr: theme.spacings.xxs,
                  flexShrink: 0,
                  position: 'relative',
                })}
              >
                <Link href={usp.url}>
                  <Asset
                    asset={usp.asset}
                    layout='fill'
                    sizes={{ 0: '50px', [theme2.breakpoints.values.md]: '46px' }}
                    sx={(theme) => ({
                      height: 'auto !important',
                      position: 'relative',
                      top: 5,
                      flexShrink: 0,
                      [theme.breakpoints.down('md')]: {
                        width: '50px !important',
                      },
                      [theme.breakpoints.up('md')]: {
                        width: '46px !important',
                      },
                    })}
                  />
                </Link>
              </Box>

              <RichText
                sxRenderer={{
                  link: (theme) => ({
                    color: theme.palette.text.primary,
                  }),
                  paragraph: (theme) => ({
                    typography: 'body1',
                    fontWeight: theme.typography.fontWeightLight,
                    pr: { xs: 0, md: theme.spacings.md },
                    maxWidth: 300,
                    wordBreak: 'keep-all',
                    '& strong': {
                      display: 'block',
                    },
                  }),
                }}
                raw={usp.description?.raw}
              />
            </Box>
          </Grid>
        )
      })}
    </Grid>
  )
}
