import { graphqlErrorByCategory } from '@graphcommerce/magento-graphql'
import {
  GuestNewsletterToggleDocument,
  GuestNewsletterToggleMutation,
  GuestNewsletterToggleMutationVariables,
} from '@graphcommerce/magento-newsletter/components/GuestNewsletterToggle/GuestNewsletterToggle.gql'
import { Form, MessageSnackbar } from '@graphcommerce/next-ui'
import { emailPattern, useFormGqlMutation } from '@graphcommerce/react-hook-form'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { Typography, Box, Button, TextField } from '@mui/material'
import { useState } from 'react'

export type NewsletterFieldProps = {
  content: string
}

export function NewsletterField(props: NewsletterFieldProps) {
  const { content } = props

  const [email, setEmail] = useState<string>()

  const form = useFormGqlMutation<
    GuestNewsletterToggleMutation,
    GuestNewsletterToggleMutationVariables & { isSubscribed?: boolean }
  >(GuestNewsletterToggleDocument, { mode: 'onChange' }, { errorPolicy: 'all' })

  const { handleSubmit, formState, error, register } = form
  const submit = handleSubmit(() => {})

  const [remainingError, noSuchEntity] = graphqlErrorByCategory({
    category: 'graphql-already-exists',
    error,
  })

  return (
    <Box
      sx={(theme) => ({
        [theme.breakpoints.down('md')]: {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          padding: `${theme.spacings.xl} ${theme.spacings.lg}`,
          backgroundColor: theme.palette.primary.contrastText,
        },
      })}
    >
      {content ? (
        <Box>
          <Typography
            variant='subtitle2'
            component='div'
            dangerouslySetInnerHTML={{
              __html: content.replace('[[', '<span>').replace(']]', '</span>'),
            }}
            sx={(theme) => ({
              fontSize: theme.typography.h4,
              fontWeight: theme.typography.fontWeightRegular,
              mb: 1,
              '& span': {
                fontWeight: theme.typography.fontWeightBold,
              },
            })}
          />
        </Box>
      ) : (
        ''
      )}
      <div>
        <Form
          noValidate
          onSubmit={submit}
          sx={(theme) => ({
            display: 'flex',
            borderRadius: '50px',
            border: `1px solid ${theme.palette.text.secondary}`,
            backgroundColor: theme.palette.primary.contrastText,
            width: 'auto',
            maxWidth: 384,
            p: 0,
            pl: theme.spacings.xs,
            mt: theme.spacings.xs,
          })}
        >
          <TextField
            type='email'
            variant='standard'
            placeholder='E-mailadres'
            error={formState.isSubmitted && !!formState.errors.email}
            value={email}
            {...register('email', {
              pattern: { value: emailPattern, message: i18n._(/* i18n */ 'Invalid email address') },
            })}
            helperText={formState.isSubmitted && formState.errors.email?.message}
            inputProps={{ autoComplete: 'email' }}
            sx={{
              flexGrow: 1,
              '& .MuiInput-root:after': {
                display: 'none',
              },
              '& .MuiInput-root:before': {
                display: 'none',
              },
              '& .MuiFormHelperText-root': {
                position: 'absolute',
                bottom: '-100%',
              },
            }}
          />
          <Button
            type='submit'
            variant='contained'
            sx={(theme) => ({
              ml: 'auto',
              BorderRadiusTopleft: 0,
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
              py: 1,
              px: theme.spacings.xs,
              borderRadius: '0 55px 55px 0',
              '&:hover': {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
              },
            })}
          >
            Inschrijven
          </Button>
          <MessageSnackbar
            open={
              !formState.isSubmitting && formState.isSubmitSuccessful && !remainingError?.message
            }
            variant='pill'
          >
            <Trans id='You are subscribed to the newsletter!' />
          </MessageSnackbar>
          <MessageSnackbar open={!!remainingError} variant='pill' severity='error'>
            <>{remainingError?.message}</>
          </MessageSnackbar>
        </Form>
      </div>
    </Box>
  )
}
