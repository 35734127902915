import { Logo as LogoBase } from '@graphcommerce/next-ui'
import { SxProps, Theme } from '@mui/material'
import React from 'react'
import svgLogo from './logo.svg'

type Props = {
  sx?: SxProps<Theme>
}

export const Logo = React.forwardRef<any, Props>((props, ref) => {
  const { sx = [] } = props
  return (
    <LogoBase
      sx={[
        (theme) => ({
          '& .GcLogo-logo': {
            width: 'auto',
            height: {
              xs: '28px',
              md: `calc(${theme.appShell.headerHeightMd} - ${theme.spacings.xxs})`,
            },
          },
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      image={{ alt: 'Da Logo', src: svgLogo, unoptimized: true }}
    />
  )
})
